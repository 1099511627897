import React, { createContext, useContext, useState, useEffect } from 'react';
import { fetchCategories } from '../../../services/apiService';

const CategoryContext = createContext();

export const useCategoryContext = () => {
    return useContext(CategoryContext);
};

export const CategoryProvider = ({ children }) => {
    const [categories, setCategories] = useState([]);
    const [activeCategory, setActiveCategory] = useState("8");

    useEffect(() => {
        console.log("Fetching categories"); // Debug log
        const loadCategories = async () => {
            try {
                const data = await fetchCategories();
                console.log("Fetched categories data:", data); // Debug log
                const sortedCategories = data.sort((a, b) => a.order - b.order);
                setCategories(sortedCategories);

                const defaultCategory = sortedCategories.find(category => category.CategoryID === 8);
                if (defaultCategory) {
                    setActiveCategory(defaultCategory.CategoryID);
                    localStorage.setItem("selectedCategory", defaultCategory.CategoryID);
                    localStorage.setItem("selectedCategoryName", defaultCategory.name_en);
                }
            } catch (error) {
                console.error("Error loading categories:", error);
            }
        };

        loadCategories(); // Call the function immediately and await its result
    }, []);

    const handleSelectCategory = (categoryId, categoryName) => {
        console.log("Selected category:", categoryId); // Debug log
        setActiveCategory(categoryId);
        localStorage.setItem("selectedCategory", categoryId);
        localStorage.setItem("selectedCategoryName", categoryName);
    };

    return (
        <CategoryContext.Provider value={{ categories, activeCategory, handleSelectCategory }}>
            {children}
        </CategoryContext.Provider>
    );
};
