import React from "react";
import "./LoadingScreen.css";
import mascotte from "./assets/media/mascotte.png"; // Adjust the path to where your mascotte.png is located

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <div className="loading-content">
        <div className="logo-container">
          <img src={mascotte} alt="Loading..." className="loading-logo" />
          <div className="progress-circle"></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
