// src/components/DemoBanner.js
import React from "react";
import "./DemoBanner.css"; // Create this file for styles

const DemoBanner = () => (
  <div className="demo-banner">
    Deze webshop is nog niet actief, bestellingen worden niet uitgevoerd
  </div>
);

export default DemoBanner;
