// Wrapper.js
import React from 'react';
import { Box } from '@mui/material';

const Wrapper = ({ children }) => {
  return (
    <Box
      sx={{
        padding: '0px',
        backgroundColor: 'white',
        overflow: 'hidden',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        fontFamily: 'Inter, sans-serif',
      }}
    >
      {children}
    </Box>
  );
};

export default Wrapper;
