import axios from "axios";
import getCookie from "../utils/csrf";

const csrfToken = getCookie('csrftoken');

// set the REACT_APP_APIVIEWS_HOST in .env of snackswift
const baseUrl = process.env.REACT_APP_APIVIEWS_HOST;

// Use REACT_APP_PRINTER_ID from environment for Kitchen Printer
const printerId = process.env.REACT_APP_PRINTER_ID;

// Use REACT_APP_PRINTER_ID_2 from environment for Kiosk Printer
const printerId2 = process.env.REACT_APP_PRINTER_ID2;

const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
    'X-CSRFToken': csrfToken,
    Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
  },
});

/* Here start the GET functions */

//fetch all orders data for kitchen
export async function fetchOrdersKitchen() {
  try {
    const response = await axios.get(`${baseUrl}/api/kitchen-screen/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching orders for kitchen:", error);
    throw error;
  }
}

//fetch promotions data
export async function fetchPromotions() {
  try {
    const response = await axios.get(`${baseUrl}/api/promotions/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching promotions:", error);
    throw error;
  }
}

//fetch Order data with status Ready and Not Ready
export async function fetchOrdersPreparing() {
  try {
    const response = await axios.get(`${baseUrl}/api/order-status-screen/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching orders preparing:", error);
    throw error;
  }
}

//fetch Order data which should be displayed on kitchen screen
export async function fetchKitchenOrders() {
  try {
    const response = await axios.get(`${baseUrl}/api/kitchen-screen/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching orders for kitchen:", error);
    throw error;
  }
}

//fetch available languages data
export async function fetchLanguages() {
  try {
    const response = await axios.get(`${baseUrl}/api/language-selection/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching languages:", error);
    throw error;
  }
}

//fetch all active products and productcategories data
export async function fetchOrderScreenData() {
  try {
    const response = await axios.get(`${baseUrl}/api/order-screen/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching orderscreendata:", error);
    throw error;
  }
}

//fetch products data filtered by product_category and language
export async function fetchProductsByCategory(category_id, language) {
  try {
    const response = await axios.get(`${baseUrl}/api/filter-products/`, {
      params: {
        category_id: category_id,
        language: language,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching filtered products:", error);
    throw error;
  }
}

//fetch product data filtered by product_id
export async function fetchProductByID(product_id) {
  try {
    const response = await axios.get(`${baseUrl}/api/products/${product_id}`, {
      params: {
        product_id: product_id,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching filtered products:", error);
    throw error;
  }
}

//fetch order items from a specific order
export async function fetchOrderItems(order_id) {
  try {
    const response = await axios.get(
      `${baseUrl}/api/order-items/${order_id}/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching order items:", error);
    throw error;
  }
}

//fetch Customer info from a specific order
export async function fetchOrderCustomerInfo(order_id) {
  try {
    const response = await axios.get(
      `${baseUrl}/api/customer-info/${order_id}/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching customer info:", error);
    throw error;
  }
}

//fetch Payment info from a specific order
export async function fetchOrderPaymentInfo(order_id) {
  try {
    const response = await axios.get(
      `${baseUrl}/api/payment-info/${order_id}/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching customer info:", error);
    throw error;
  }
}

// Update Payment info for a specific order
export async function UpdateOrderPaymentInfo(orderId, paymentRef) {
  try {
    const response = await axios.patch(
      `${baseUrl}/api/payment-update/${orderId}/`,
      {
        paymentRef, // Assuming the backend expects an object with the paymentRef property
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating payment info:", error);
    throw error;
  }
}

//fetch Products and Categories
export async function fetchProducts() {
  try {
    const response = await axios.get(`${baseUrl}/api/products/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching Products:", error);
    throw error;
  }
}

//fetch all Orders
export async function fetchOrders(page = 1) {
  try {
    const response = await axios.get(`${baseUrl}/api/orders/?page=${page}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching Orders:", error);
    throw error;
  }
}

export async function fetchOrderStatus(orderId) {
  try {
    const response = await axios.get(
      `${baseUrl}/api/orders/status-${orderId}/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching order status:", error);
    throw error;
  }
}

//fetch all Deleted Products
export async function fetchDeletedProducts() {
  try {
    const response = await axios.get(`${baseUrl}/api/deleted-products/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching Deleted Products:", error);
    throw error;
  }
}

//fetch all Deleted Categories
export async function fetchDeletedCategories() {
  try {
    const response = await axios.get(`${baseUrl}/api/deleted-categories/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching Deleted Categories:", error);
    throw error;
  }
}

//fetch all archived Orders
export async function fetchArchivedOrders() {
  try {
    const response = await axios.get(`${baseUrl}/api/archived-orders/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching Archived Orders:", error);
    throw error;
  }
}

/* Here start the POST functions */

//mark an order as ready
export async function markOrderAsReady(order_id) {
  try {
    const response = await axios.post(
      `${baseUrl}/api/orders/mark-ready/${order_id}`,
      null,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error marking order as ready:", error);
    throw error;
  }
}

//mark an order as ready
export async function markOrderAsDone(order_id) {
  try {
    const response = await axios.post(
      `${baseUrl}/api/orders/mark-done/${order_id}`,
      null,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error marking order as done:", error);
    throw error;
  }
}

//add a product to a category
export async function addProductToCategory(product_id, category_id) {
  try {
    const requestBody = {
      product_id,
      category_id,
    };

    const response = await axios.post(
      `${baseUrl}/api/add-product-to-category/`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error adding product to category:", error);
    throw error;
  }
}

//remove a product from a category
export async function removeProductFromCategory(product_id, category_id) {
  try {
    const requestBody = {
      product_id,
      category_id,
    };

    const response = await axios.post(
      `${baseUrl}/api/remove-product-from-category/`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error removing product from category:", error);
    throw error;
  }
}

//create an order from an array of variables
/* example body: 
const requestBody = {
  "customerId": 1,
  "promoId": 1,
  "language_id": "NL",
  "selection_method": "Ter plaatse",
  "extra_order_info": "Some extra info",
  "item_discount": "0.00",
  "subTotal": "5.45",
  "tax": "1.45",
  "total": "6.90",
  "discount": "5.00",
  "final_price": "1.90",
  "orderStatus": "Not Ready",
  "pointsChange": 0,
  "items": [
    {
      "productId": 4,
      "quantity": 2,
      "price": "2.50"
    }
  ],
  "paymentType": "bancontact",
  "paymentStatus": 1
};
*/
export async function createOrder(requestBody) {
  try {
    const response = await axios.post(
      `${baseUrl}/api/create-order/`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating order:", error);
    throw error;
  }
}

//upload an image (for product or category creation)
/* example how this can work 
const FormData = require('form-data');
const fs = require('fs'); // Node.js 'fs' module for reading the image file

async function fetchTest(imageFile) {
  try {
    const formData = new FormData();
    formData.append('image_url', fs.createReadStream(imageFile));

    const response = await axios.post(`${baseUrl}/api/uploadimg/`, formData, {
      headers: {
        ...formData.getHeaders(), // Set the headers for form data
        'Authorization': `Token 8e0a612bc9ca6feae945894152f7a9a27371ea5f`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

// Execute the fetch function
async function main() {
  try {
    // Replace 'image.jpg' with the path to the actual image file you want to upload
    const imageFilePath = './src/assets/media/productimages/Ragoezie.png';

    const data = await fetchTest(imageFilePath);
    console.log('Image uploaded:', data);
  } catch (error) {
    console.error('Error uploading image:', error);
  }
}


main();
*/

export async function uploadImage(formData) {
  try {
    const response = await axios.post(`${baseUrl}/api/uploadimg/`, formData, {
      headers: {
        Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
}

//create an product from an array of variables
/* example body: 
const requestBody = {
    "sku": "ABC123",
    "name_nl": "Product Name NL",
    "name_fr": "Product Name FR",
    "name_en": "Product Name EN",
    "status": true,
    "price": 19.99,
    "description": "Product description",
    "allergen": "Product allergen information",
    "ingredients": [],
    "upsells": [],
    "categories": [4,8],
    "image_url": "/media/burgers.png"
};
*/
export async function createProduct(requestBody) {
  try {
    const response = await axios.post(
      `${baseUrl}/api/products/create/`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating product:", error);
    throw error;
  }
}

//recover a product
export async function recoverProduct(product_id) {
  try {
    const response = await axios.post(
      `${baseUrl}/api/recover-product/${product_id}/`,
      null,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error recovering product:", error);
    throw error;
  }
}

//create an product category from an array of variables
/* example body: 
const requestBody = {
    "name_nl": "Categorie Naam NL",
    "name_fr": "Nom de Catégorie FR",
    "name_en": "Category Name EN",
    "image_url": "/media/burgers.png",
    "status": true,
    "order": 100
};
*/
export async function createCategory(requestBody) {
  try {
    const response = await axios.post(
      `${baseUrl}/api/categories/create/`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating category:", error);
    throw error;
  }
}

//recover a product category
export async function recoverCategory(category_id) {
  try {
    const response = await axios.post(
      `${baseUrl}/api/recover-category/${category_id}/`,
      null,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error recovering category:", error);
    throw error;
  }
}

//recover an order
export async function recoverOrder(order_id) {
  try {
    const response = await axios.post(
      `${baseUrl}/api/recover-order/${order_id}/`,
      null,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error recovering order:", error);
    throw error;
  }
}

// Function to create a customer
export async function createCustomer(data) {
  try {
    const response = await axios.post(`${baseUrl}/api/customer/create/`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error creating customer:", error);
    throw error;
  }
}

/* Here start the PATCH functions */

//edit an order from an array of possible variables
/* example body, any of the following fields adjusted to the needs (not all needed): 
const requestBody = {
  "customerId": 1,
  "promoId": 1,
  "language_id": "NL",
  "selection_method": "Ter plaatse",
  "extra_order_info": "Some extra info",
  "item_discount": "0.00",
  "subTotal": "5.45",
  "tax": "1.45",
  "total": "6.90",
  "discount": "5.00",
  "final_price": "1.90",
  "orderStatus": "Not Ready",
  "pointsChange": 0,
  "items": [
    {
      "productId": 4,
      "quantity": 2,
      "price": "2.50"
    }
  ],
  "paymentType": "bancontact",
  "paymentStatus": 1
};
*/
export async function editOrder(order_id, requestBody) {
  try {
    const response = await axios.patch(
      `${baseUrl}/api/edit-order/${order_id}/`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error editing order:", error);
    throw error;
  }
}

//edit a product from an array of possible variables
/* example body, any of the following fields adjusted to the needs (not all needed): 
const requestBody = {
    "sku": "ABC123",
    "name_nl": "Product Name NL",
    "name_fr": "Product Name FR",
    "name_en": "Product Name EN",
    "status": true,
    "price": 19.99,
    "description": "Product description",
    "allergen": "Product allergen information",
    "ingredients": [],
    "upsells": [],
    "categories": [4,8],
    "image_url": "/media/burgers.png"
};
*/
export async function editProduct(product_id, requestBody) {
  try {
    const response = await axios.patch(
      `${baseUrl}/api/products/edit/${product_id}`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error editing product:", error);
    throw error;
  }
}

//edit a product category from an array of possible variables
/* example body, any of the following fields adjusted to the needs (not all needed): 
const requestBody = {
    "name_nl": "Categorie Naam NL",
    "name_fr": "Nom de Catégorie FR",
    "name_en": "Category Name EN",
    "image_url": "/media/burgers.png",
    "status": true,
    "order": 100
};
*/
export async function editCategory(category_id, requestBody) {
  try {
    const response = await axios.patch(
      `${baseUrl}/api/categories/edit/${category_id}/`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error editing category:", error);
    throw error;
  }
}

/* Here start the DELETE functions */
//delete or archive a product
export async function deleteProduct(product_id) {
  try {
    const response = await axios.delete(
      `${baseUrl}/api/products/delete/${product_id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting product:", error);
    throw error;
  }
}

//delete or archive a product category
export async function deleteCategory(category_id) {
  try {
    const response = await axios.delete(
      `${baseUrl}/api/categories/delete/${category_id}/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting category:", error);
    throw error;
  }
}

//delete or archive an order
export async function deleteOrder(order_id) {
  try {
    const response = await axios.delete(
      `${baseUrl}/api/orders/delete/${order_id}/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error archiving order:", error);
    throw error;
  }
}

// Function to handle login
export async function loginUser(username, password) {
  try {
    const response = await axios.post(
      `${baseUrl}/api/login/`,
      {
        username: username,
        password: password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data; // This will contain the token upon successful login
  } catch (error) {
    console.error("Login error:", error.response ? error.response.data : error);
    throw error;
  }
}

// Function to handle sending a print job to Kitchen Printer
export async function sendPrintJob(title, content, contentType = "pdf_base64") {
  try {
    const response = await axios.post(
      `${baseUrl}/api/print-job/`,
      {
        printer_id: printerId,
        title: title,
        content: content, // This should be the base64 encoded content of the file
        content_type: contentType,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data; // This will contain the response from the server
  } catch (error) {
    console.error(
      "Print job error:",
      error.response ? error.response.data : error
    );
    throw error;
  }
}

// Function to handle sending a print job to Kitchen Kiosk
export async function sendPrintJobKiosk(
  title,
  content,
  contentType = "pdf_base64"
) {
  try {
    const response = await axios.post(
      `${baseUrl}/api/print-job/`,
      {
        printer_id: printerId2,
        title: title,
        content: content, // This should be the base64 encoded content of the file
        content_type: contentType,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data; // This will contain the response from the server
  } catch (error) {
    console.error(
      "Print job error:",
      error.response ? error.response.data : error
    );
    throw error;
  }
}

// Function to handle creation of an Ingredient
export async function createIngredient(requestBody) {
  try {
    const response = await axios.post(
      `${baseUrl}/api/ingredients/create/`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating ingredient:", error);
    throw error;
  }
}

// Function to handle editing an Ingredient
export async function editIngredient(ingredient_id, requestBody) {
  try {
    const response = await axios.patch(
      `${baseUrl}/api/ingredients/edit/${ingredient_id}`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error editing ingredient:", error);
    throw error;
  }
}

// Function handling deletion of an Ingredient
export async function deleteIngredient(ingredient_id) {
  try {
    const response = await axios.delete(
      `${baseUrl}/api/ingredients/delete/${ingredient_id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting ingredient:", error);
    throw error;
  }
}

// Function handling the recovery of an Ingredient
export async function recoverIngredient(ingredient_id) {
  try {
    const response = await axios.post(
      `${baseUrl}/api/recover-ingredient/${ingredient_id}/`,
      null,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error recovering ingredient:", error);
    throw error;
  }
}

// Function handling fetching all Ingredients
export async function fetchIngredients() {
  try {
    const response = await axios.get(`${baseUrl}/api/ingredients/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching ingredients:", error);
    throw error;
  }
}

// Function handling fetching 1 ingredient by ID
export async function fetchIngredientByID(ingredient_id) {
  try {
    const response = await axios.get(
      `${baseUrl}/api/ingredients/${ingredient_id}`,
      {
        params: {
          ingredient_id: ingredient_id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching ingredient by ID:", error);
    throw error;
  }
}

// Function handling fetching all deleted Ingredients
export async function fetchDeletedIngredients() {
  try {
    const response = await axios.get(`${baseUrl}/api/deleted-ingredients/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching Deleted Ingredients:", error);
    throw error;
  }
}

// Function to handle creation of a TimeSlot
export async function createTimeSlot(requestBody) {
  try {
    const response = await axios.post(
      `${baseUrl}/api/timeslots/`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating time slot:", error);
    throw error;
  }
}

// Function to handle editing a TimeSlot
export async function editTimeSlot(timeslotId, requestBody) {
  try {
    const response = await axios.patch(
      `${baseUrl}/api/timeslots/${timeslotId}/`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error editing time slot:", error);
    throw error;
  }
}

// Function to handle retrieving all TimeSlots
export async function getTimeSlots() {
  try {
    const response = await axios.get(`${baseUrl}/api/timeslots/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching time slots:", error);
    throw error;
  }
}

// Function to handle retrieving a single TimeSlot by ID
export async function getTimeSlot(timeslotId) {
  try {
    const response = await axios.get(
      `${baseUrl}/api/timeslots/${timeslotId}/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching time slot:", error);
    throw error;
  }
}

// Function to handle deleting a TimeSlot
export async function deleteTimeSlot(timeslotId) {
  try {
    await axios.delete(`${baseUrl}/api/timeslots/${timeslotId}/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    });
  } catch (error) {
    console.error("Error deleting time slot:", error);
    throw error;
  }
}

// Function to handle creating or updating StoreSettings
export async function createOrUpdateStoreSettings(requestBody) {
  try {
    const response = await axios.post(
      `${baseUrl}/api/storesettings/`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating or updating store settings:", error);
    throw error;
  }
}

// Function to handle retrieving StoreSettings
export async function getStoreSettings() {
  try {
    const response = await axios.get(`${baseUrl}/api/storesettings/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching store settings:", error);
    throw error;
  }
}

// Function to handle deleting StoreSettings
export async function deleteStoreSettings() {
  try {
    await axios.delete(`${baseUrl}/api/storesettings/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    });
  } catch (error) {
    console.error("Error deleting store settings:", error);
    throw error;
  }
}

// Function handling fetching orders based on pickup time
export async function fetchPickupTimeOrders() {
  try {
    const response = await axios.get(`${baseUrl}/api/pickup-time/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    });
    console.log('Fetched pickup time orders:', response.data); // Add this line
    return response.data;
  } catch (error) {
    console.error("Error fetching pickup time orders:", error);
    throw error;
  }
}

export async function exportCategoriesToCSV() {
  try {
    const response = await axios.get(`${baseUrl}/api/export_categories_csv/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
      responseType: 'blob', // Important for handling file download
    });
    if (response.status === 200) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'product_categories.csv');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } else {
      console.error("Failed to export categories to CSV");
    }
  } catch (error) {
    console.error("Error exporting categories to CSV:", error);
    throw error;
  }
}

export const importCategoriesFromCSV = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await axiosInstance.post(`/api/import_categories_csv/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error importing categories from CSV:', error);
    throw error;
  }
};

export async function exportProductsToCSV() {
  try {
    const response = await axios.get(`${baseUrl}/api/export_products_csv/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
      responseType: 'blob', // Important for handling file download
    });
    if (response.status === 200) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'products.csv');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } else {
      console.error("Failed to export products to CSV");
    }
  } catch (error) {
    console.error("Error exporting products to CSV:", error);
    throw error;
  }
}

export const importProductsFromCSV = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await axiosInstance.post(`/api/import_products_csv/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error importing products from CSV:', error);
    throw error;
  }
};

// Fetch categories data
export async function fetchCategories() {
  try {
    const response = await axios.get(`${baseUrl}/api/categories/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching categories:", error);
    throw error;
  }
}

// Fetch products with category data
export async function fetchProductsWithCategories(categoryId) {
  try {
    const response = await axios.get(`${baseUrl}/api/products-with-categories/`, {
      params: { category_id: categoryId },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
}




