import React, { useState, useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { LanguageProvider } from "./components/context/LanguageContext"; // adjust path as needed
import LoadingScreen from "./LoadingScreen"; // Import the loading screen component
import ProtectedRoute from "./components/ProtectedRoute"; // Added from feature/snac-31 branch
import DemoBanner from "./components/DemoBanner";
import Wrapper from './Wrapper'; // Import the Wrapper component
import { CategoryProvider } from "./components/orderscreen/hooks/CategoryContext";
import { ProductProvider } from "./components/orderscreen/hooks/ProductContext";

// Lazy-loaded components
const ChooseScreen = lazy(() => import("./pages/screens/ChooseScreen"));
const CheckoutScreen = lazy(() => import("./pages/screens/CheckoutScreen"));
const PaymentScreen = lazy(() => import("./pages/screens/PaymentScreen"));
const OrderConfirmedScreen = lazy(() => import("./pages/screens/OrderConfirmedScreen"));
const LoginPage = lazy(() => import("./pages/admin/Login"));
const DashboardNew = lazy(() => import("./pages/admin/dashboard/Dashboard"));
const KitchenOrderScreen = lazy(() => import("./pages/screens/KitchenScreen"));
const OrderStatusScreen = lazy(() => import("./pages/screens/OrderStatusScreen"));
const OrderStatusPersonal = lazy(() => import("./pages/screens/OrderStatusPersonal"));
const NotFound = lazy(() => import("./pages/screens/404"));
const PaymentFailedScreen = lazy(() => import("./pages/screens/PaymentFailed")); // Added from development branch
const DigitalMenuScreen = lazy(() => import("./pages/screens/DigitalMenu"));
const MSPPaymentScreen = lazy(() => import("./pages/screens/MSPPaymentScreen"));
const OrderingMain = lazy(() => import("./components/orderscreen/OrderingMain"));

function App() {
  const [loading, setLoading] = useState(false);
  const [isTestMode, setIsTestMode] = useState(false);
  const paymentProvider = process.env.REACT_APP_PAYMENT_PROVIDER || 'mollie';

  useEffect(() => {
    // Check if the app is in test mode
    if (process.env.REACT_APP_TEST_MODE === "true") {
      setIsTestMode(true);
    }
  }, []);

  return (
    <LanguageProvider>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div>
          {isTestMode && <DemoBanner />}
          <Router>
            <Suspense fallback={<LoadingScreen />}>
              <CategoryProvider>
                <ProductProvider>
                  <Routes>
                    <Route path="/" element={<Wrapper><ChooseScreen /></Wrapper>} />

                    <Route path="/orderingnew" element={<Wrapper><OrderingMain /></Wrapper>} />

                    <Route path="/choose" element={<Wrapper><ChooseScreen /></Wrapper>} />
                    <Route path="/ordering" element={<Wrapper><OrderingMain /></Wrapper>} />
                    <Route path="/checkout" element={<Wrapper><CheckoutScreen /></Wrapper>} />
                    <Route path="/payment" element={
                      <Wrapper>
                        {paymentProvider === 'multisafepay'
                          ? <MSPPaymentScreen />
                          : <PaymentScreen />
                        }
                      </Wrapper>
                    } />
                    <Route path="/payment-failed" element={<Wrapper><PaymentFailedScreen /></Wrapper>} />
                    <Route path="/order-confirmed" element={<Wrapper><OrderConfirmedScreen /></Wrapper>} />
                    <Route path="/login" element={<Wrapper><LoginPage /></Wrapper>} />
                    <Route
                      path="/dashboard"
                      element={
                        <Wrapper>
                          <ProtectedRoute>
                            <DashboardNew />
                          </ProtectedRoute>
                        </Wrapper>
                      }
                    />
                    <Route path="/kitchen-screen" element={<Wrapper><KitchenOrderScreen /></Wrapper>} />
                    <Route path="/order-status-screen" element={<Wrapper><OrderStatusScreen /></Wrapper>} />
                    <Route path="/digital-menu1" element={<Wrapper><DigitalMenuScreen page={1} /></Wrapper>} />
                    <Route path="/digital-menu2" element={<Wrapper><DigitalMenuScreen page={2} /></Wrapper>} />
                    <Route path="/digital-menu3" element={<Wrapper><DigitalMenuScreen page={3} /></Wrapper>} />
                    <Route path="/digital-menu4" element={<Wrapper><DigitalMenuScreen page={4} /></Wrapper>} />
                    <Route path="/order-status" element={<Wrapper><OrderStatusPersonal /></Wrapper>} />
                    <Route path="*" element={<Wrapper><NotFound /></Wrapper>} />
                  </Routes>
                </ProductProvider>
              </CategoryProvider>
            </Suspense>
          </Router>
        </div>
      )}
    </LanguageProvider>
  );
}

export default App;
