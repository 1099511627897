import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { fetchProductsWithCategories } from '../../../services/apiService';

const ProductContext = createContext();

export const useProductContext = () => {
    return useContext(ProductContext);
};

export const ProductProvider = ({ children }) => {
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const loadProducts = useCallback(async (categoryId) => {
        console.log("Loading products for category:", categoryId); // Debug log
        setIsLoading(true);
        try {
            const data = await fetchProductsWithCategories(categoryId);
            setProducts(data);
        } catch (error) {
            console.error("Error loading products:", error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    return (
        <ProductContext.Provider value={{ products, loadProducts, isLoading }}>
            {children}
        </ProductContext.Provider>
    );
};
