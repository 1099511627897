import React, { createContext, useState, useContext, useEffect } from 'react';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState(localStorage.getItem('selected_language') || 'EN');

    useEffect(() => {
        const syncLanguage = () => {
            setLanguage(localStorage.getItem('selected_language') || 'EN');
        };

        window.addEventListener('storage', syncLanguage);
        return () => {
            window.removeEventListener('storage', syncLanguage);
        };
    }, []);

    const changeLanguage = (lang) => {
        localStorage.setItem('selected_language', lang);
        setLanguage(lang);
    };

    return (
        <LanguageContext.Provider value={{ language, changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => useContext(LanguageContext);
